<template>
  <div>
    <b-card>
      <h3>เอกสารยืนยันตัวตน</h3>
      <div v-if="loading" class="mt-2">
        <b-skeleton width="85%" />
        <b-skeleton width="55%" />
        <b-skeleton width="70%" />
      </div>
      <div v-else>
        <b-row>
          <b-col sm="12" md="6">
            <p class="mb-50">
              สถานะ :
              <b-badge class="ml-50" :variant="statusSettingInfoVariant[accountDetails.verify_setting_info.status]">
                {{ statusSettingInfoText[accountDetails.verify_setting_info.status] }}
              </b-badge>
            </p>
          </b-col>
          <b-col sm="12" md="6">
            <p>หมายเหตุ : {{ accountDetails.verify_setting_info.note || '-' }}</p>
          </b-col>
        </b-row>
        <b-row v-if="accountDetails.verify_setting_info.status !== 'Wait Document'">
          <b-col v-if="accountDetails" sm="12" class="mb-50 mt-50">
            <h5 class="mb-0">รายละเอียดทั่วไป</h5>
            <hr class="mt-50" />
          </b-col>
          <b-col v-if="accountDetails" sm="12" md="6">
            <table class="w-100">
              <tr v-for="info in listSettingInfoSection1" :key="info.key">
                <th class="pb-50 w-50">
                  <feather-icon :icon="info.icon" class="mr-75" />
                  <span class="font-weight-bold">{{ info.title }}</span>
                </th>
                <td v-if="info.renderDetail" class="pb-50">
                  {{ info.renderDetail(accountDetails) }}
                </td>
                <td v-else class="pb-50">
                  {{ accountDetails[info.key] }}
                </td>
              </tr>
            </table>
          </b-col>
          <b-col v-if="accountDetails" sm="12" md="6">
            <table class="w-100">
              <tr v-for="info in listSettingInfoSection2" :key="info.key">
                <th class="pb-50 w-50">
                  <feather-icon :icon="info.icon" class="mr-75" />
                  <span class="font-weight-bold">{{ info.title }}</span>
                </th>
                <td v-if="info.renderDetail" class="pb-50">
                  {{ info.renderDetail(accountDetails) }}
                </td>
                <td v-else class="pb-50">
                  {{ accountDetails[info.key] }}
                </td>
              </tr>
            </table>
          </b-col>

          <b-col v-if="accountDetails" sm="12" class="mb-50 mt-2">
            <h5 class="mb-0">รายละเอียดรูปหลักฐาน</h5>
            <hr class="mt-50" />
          </b-col>
          <b-col v-if="accountDetails" sm="12" md="4">
            <p class="text-md-center">
              หน้าสมุดธนาคาร
            </p>
            <div class="preview_image d-flex flex-column align-items-center my-2">
              <b-img
                :src="accountDetails.image_bank_info"
                height="100px"
                width="auto"
                class="cursor-zoom"
                @click="gZoomImage"
              />
            </div>
          </b-col>
          <b-col v-if="accountDetails" sm="12" md="4">
            <p class="text-md-center">
              สำเนาบัตรประชาชน
            </p>
            <div class="d-flex flex-column align-items-center my-2">
              <b-img
                :src="accountDetails.image_card_info"
                height="100px"
                width="auto"
                class="cursor-zoom"
                @click="gZoomImage"
              />
            </div>
          </b-col>
          <b-col v-if="accountDetails" sm="12" md="4">
            <p class="text-md-center">
              รูปคู่บัตรประชาชน
            </p>
            <div class="d-flex flex-column align-items-center my-2">
              <b-img
                :src="accountDetails.image_card_and_user_info"
                height="100px"
                width="auto"
                class="cursor-zoom"
                @click="gZoomImage"
              />
            </div>
          </b-col>
          <b-col class="d-flex justify-content-sm-end flex-column flex-sm-row mt-2">
            <b-button
              v-if="accountDetails.verify_setting_info.status !== 'Approval'"
              variant="success"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              class="mb-50 mb-sm-0 mr-0 mr-sm-50"
              @click="confirmAccountDetails('settingInfo')"
            >
              อนุมัติ
            </b-button>
            <b-button
              v-if="accountDetails.verify_setting_info.status !== 'Reject'"
              variant="danger"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="openModalReject('settingInfo')"
            >
              ไม่อนุมัติ
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="refModalReject"
      :title="modalModel.title"
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      @ok="submitReject"
      @cancel="clearModalModel"
    >
      <p>โปรดระบุเหตุผลที่ไม่อนุมัติ</p>
      <b-form-textarea v-model="modalModel.note" />
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      listSettingInfoSection1: [
        {
          key: 'account',
          title: 'ชื่อ-นามสกุล',
          type: 'string',
          renderDetail: obj => `${obj.first_name} ${obj.last_name}`,
          icon: 'UserIcon',
        },
        {
          key: 'phone_number',
          title: 'เบอร์โทรศัพท์',
          type: 'string',
          icon: 'PhoneIcon',
        },
        {
          key: 'pid',
          title: 'รหัสบัตรประชาชน',
          type: 'string',
          icon: 'LockIcon',
        },
        {
          key: 'bank_type',
          title: 'ชื่อธนาคาร',
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'major',
          title: 'สาขาธนาคาร',
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'bank_no',
          title: 'เลขที่บัญชี',
          type: 'string',
          icon: 'CircleIcon',
        },
      ],
      listSettingInfoSection2: [
        {
          key: 'address',
          title: 'ที่อยู่',
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'address_json.subdistrict',
          title: 'ตำบล/แขวง',
          renderDetail: obj => obj.address_json.subdistrict,
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'address_json.district',
          title: 'อำเภอ/เขต',
          renderDetail: obj => obj.address_json.district,
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'address_json.province',
          title: 'จังหวัด',
          renderDetail: obj => obj.address_json.province,
          type: 'string',
          icon: 'CircleIcon',
        },
        {
          key: 'address_json.zipcode',
          title: 'รหัสไปรษณีย์',
          renderDetail: obj => obj.address_json.zipcode,
          type: 'string',
          icon: 'CircleIcon',
        },
      ],
      statusSettingInfoText: {
        'Wait Document': 'รอเอกสารยืนยันตัวตนจากผู้สมัคร AF',
        Submit: 'รอแอดมินอนุมัติเอกสารยืนยันตัวตน',
        Reject: 'ไม่อนุมัติ',
        Approval: 'อนุมัติ',
      },
      statusSettingInfoVariant: {
        'Wait Document': 'secondary',
        Submit: 'warning',
        Reject: 'danger',
        Approval: 'success',
      },
      accountDetails: null,
      loading: false,
      modalModel: {
        title: '',
        note: '',
        key: '',
      },
    }
  },
  created() {
    this.fetchVerifyUserAFInfo(this.userId)
  },
  methods: {
    async fetchVerifyUserAFInfo(userId) {
      this.loading = true
      const resp = await this.api.getV2(`api/admin/affiliate/bank-info/${userId}`).catch(() => null)

      if (resp && resp.code === 200) {
        this.accountDetails = { ...resp.data }
      }

      this.loading = false
    },
    async confirmAccountDetails(key) {
      if (!key) return
      const formatConfirm = {
        settingInfo: 'อนุมัติเอกสารยืนยันตัวตน',
        agreementInfo: 'อนุมัติเอกสารสัญญา',
      }

      const checkConfirm = await this.gCheckConfirmV1(formatConfirm[key])

      if (!checkConfirm) return

      const formatUrl = {
        settingInfo: 'api/admin/affiliate/confirm-bank-account-data',
        agreementInfo: 'api/admin/affiliate/confirm-bank-document-mail',
      }

      const url = formatUrl[key]

      this.loading = true
      const resp = await this.api
        .post(url, {
          status: 'Approval',
          user_id: this.userId,
        })
        .catch(() => null)
      this.loading = false
      // console.log('confirmAccountDetails', resp)
      if (!resp) {
        this.gDisplayToast('พบข้อผิดพลาด', 'ไม่สามารถดำเนินการต่อได้ โปรดติดต่อผู้ดูแลระบบ', 'danger')
      } else {
        // await this.getAccountDetailsById(this.userId)
        this.gDisplayToast('ดำเนินการสำเร็จ')
        this.accountDetails = { ...resp }
      }
    },
    clearModalModel() {
      this.modalModel = {
        title: '',
        note: '',
        key: '',
      }
    },
    openModalReject(key) {
      if (!key) return
      const format = {
        settingInfo: 'ไม่อนุมัติเอกสารยืนยันตัวตน',
        agreementInfo: 'ไม่อนุมัติเอกสารสัญญา',
      }
      this.modalModel.title = format[key]
      this.modalModel.key = key
      this.modalModel.note = ''

      this.$refs.refModalReject.show()
    },
    async submitReject(bvEvent) {
      const { key, note } = this.modalModel

      if (!note) {
        this.gDisplayToast('โปรดระบุสาเหตุที่ไม่อนุมัติ', '', 'danger')
        bvEvent.preventDefault()
        return
      }

      const formatUrl = {
        settingInfo: 'api/admin/affiliate/confirm-bank-account-data',
        agreementInfo: 'api/admin/affiliate/confirm-bank-document-mail',
      }

      this.loading = true
      const resp = await this.api
        .post(formatUrl[key], { status: 'Reject', note, user_id: this.userId })
        .catch(() => null)
      // console.log('submitReject', resp)
      this.loading = false
      if (!resp) {
        this.gDisplayToast('พบข้อผิดพลาด', 'ไม่สามารถดำเนินการต่อได้ โปรดติดต่อผู้ดูแลระบบ', 'danger')
      } else {
        // await this.getAccountDetailsById(this.userId)
        this.gDisplayToast('ดำเนินการสำเร็จ')
        this.accountDetails = { ...resp }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
